import React from 'react'
import Layout from '../components/Layout'
import { FaLinkedin } from "react-icons/fa"
import {StaticImage} from 'gatsby-plugin-image'
import Seo from '../components/Seo'
import contact from "../assets/images/contact_info.jpg"

const Contact = () => {
    return (
<Layout>
<Seo  title="Contact"/>
    <main className="page">
        <section className="about-page">
        <article>
        <p>
        <h5>Kantoor</h5>
        Neringpassage 170 <br/>
        8224 JR Lelystad <br />
        </p>
        <p>
        <h5>Contact informatie</h5> 
        Bert Krol <br/>
        <img src={contact} alt="contact info" />
        </p>
        <p> 
        <a href="https://nl.linkedin.com/in/bertkrol" target="_blank" rel="noreferrer" aria-label="Linkedin Icon">
            <FaLinkedin size="30" />
        </a>
        </p>
        </article>
        <StaticImage 
                src="../assets/images/contactphoto.jpg" 
                alt="Hand with plane pointing to map" 
                className = "about-img"
                placeholder ="blurred"/>
        </section>
    </main>
</Layout>
   
    )
}

export default Contact
